import logo from './logo.svg';
import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import './index.css';
// 404

import Error from './Error/error404.js';
import LoginAction from './store/action/LoginAction.js';

import HomeAction from './store/action/HomeAction.js';

import ShouyeAction from './store/action/ShouyeAction.js';

import XmjhAction from './store/action/XmjhAction.js';

import MrtjjlAction from './store/action/MrtjjlAction.js';

import WdbmxAction from './store/action/WdbmxAction.js';

import JymxAction from './store/action/JymxAction.js';

import JckglAction from './store/action/JckglAction.js';
import Jckgl2Action from './store/action/Jckgl2Action.js';

import ClwxmxAction from './store/action/ClwxmxAction.js';

import DhntmxAction from './store/action/DhntmxAction.js';
import StxstjAction from './store/action/StxstjAction.js';

import YszkmxAction from './store/action/YszkmxAction.js';
import HkmxAction from './store/action/HkmxAction.js';

import YfzkmxAction from './store/action/YfzkmxAction.js';

import SzqkAction from './store/action/SzqkAction.js';
import Szqk2Action from './store/action/Szqk2Action.js';
import FapiaoAction from './store/action/FapiaoAction.js';

import KqAction from './store/action/KqAction.js';

import GzjsAction from './store/action/GzjsAction.js';

import JsmxAction from './store/action/JsmxAction.js';

import HtxxAction from './store/action/HtxxAction.js';

import UserAction from './store/action/UserAction.js';

import YgxxAction from './store/action/YgxxAction.js';

import ClxxAction from './store/action/ClxxAction.js';

import XinxibiaoAction from './store/action/XinxibiaoAction.js';

import SwcbAction from './store/action/SwcbAction.js';

import ScjhfxAction from './store/action/ScjhfxAction.js';

import YhtjAction from './store/action/YhtjAction.js';
import WxtjAction from './store/action/WxtjAction.js';

import JcxsAction from './store/action/JcxsAction.js';
import JcxstjAction from './store/action/JcxstjAction.js';

import LrfxAction from './store/action/LrfxAction.js';
import CbfxAction from './store/action/CbfxAction.js';

import WjstjAction from './store/action/WjstjAction.js';

import YetjAction from './store/action/YetjAction.js';
import FpjsAction from './store/action/FpjsAction.js';

import store from "./store/store.js";
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            route: []
        }
        store.subscribe(() => {
            // console.log('state状态改变了，新状态如下')
            // console.log(store.getState().data)
            // if(store.getState().data.info.qx){
            //     var route = []
            //     if(store.getState().data.info.qx.includes('1')){

            //     }
            // }
        })
    }
    componentDidMount() {
        // this.props.IS_MOBILE();
        // console.log(store.getState().data.info.qx.includes('6'))
    }
    Shouye() {
        let route = [
            <Route exact path="/" component={ShouyeAction} />,
        ]
        return route
    }
    Xmjh() {
        let route = [
            <Route exact path="/xmjh" component={XmjhAction} />,
        ]
        return route
    }
    Mrtjjl() {
        let route = [
            <Route exact path="/mrtjjl" component={MrtjjlAction} />,
        ]
        return route
    }
    Wdbmx() {
        let route = [
            <Route exact path="/wdbmx" component={WdbmxAction} />,
        ]
        return route
    }
    Jymx() {
        let route = [
            <Route exact path="/Jymx" component={JymxAction} />,
            <Route exact path="/Jymx/:iswz" component={JymxAction} />,
        ]
        return route
    }
    Jckgl() {
        let route = [
            <Route exact path="/jckgl" component={JckglAction} />,
        ]
        return route
    }
    Jckgl2() {
        let route = [
            <Route exact path="/jckgl2" component={Jckgl2Action} />,
        ]
        return route
    }
    Clwxmx() {
        let route = [
            <Route exact path="/clwxmx" component={ClwxmxAction} />,
            <Route exact path="/clwxmx/:iswz" component={ClwxmxAction} />,
        ]
        return route
    }
    Dhntmx() {
        let route = [
            <Route exact path="/dhntmx" component={DhntmxAction} />,
        ]
        return route
    }
    Yszkmx() {
        let route = [
            <Route exact path="/yszkmx" component={YszkmxAction} />,
            <Route exact path="/hkmx" component={HkmxAction} />,
        ]
        return route
    }
    Yfzkmx() {
        let route = [
            <Route exact path="/yfzkmx" component={YfzkmxAction} />,
        ]
        return route
    }
    Szqk() {
        let route = [
            <Route exact path="/szqk" component={SzqkAction} />,
            <Route exact path="/liushui" component={Szqk2Action} />,
            <Route exact path="/fapiao" component={FapiaoAction} />,
        ]
        return route
    }
    Kq() {
        let route = [
            <Route exact path="/kq" component={KqAction} />,
        ]
        return route
    }
    Gzjs() {
        let route = [
            <Route exact path="/gzjs" component={GzjsAction} />,
            <Route exact path="/gzjs/:iswz" component={GzjsAction} />,
        ]
        return route
    }
    Jsmx() {
        let route = [
            <Route exact path="/jsmx" component={JsmxAction} />,
        ]
        return route
    }
    Htxx() {
        let route = [
            <Route exact path="/htxx" component={HtxxAction} />,
        ]
        return route
    }
    User() {
        let route = [
            <Route exact path="/user" component={UserAction} />,
        ]
        return route
    }
    Ygxx() {
        let route = [
            <Route exact path="/ygxx" component={YgxxAction} />,
        ]
        return route
    }
    Clxx() {
        let route = [
            <Route exact path="/clxx" component={ClxxAction} />,
        ]
        return route
    }
    Xinxibiao() {
        let route = [
            <Route exact path="/xinxibiao" component={XinxibiaoAction} />,
        ]
        return route
    }
    Swcb() {
        let route = [
            <Route exact path="/swcb" component={SwcbAction} />,
        ]
        return route
    }
    Scjhfx() {
        let route = [
            <Route exact path="/scjhfx" component={ScjhfxAction} />,
        ]
        return route
    }
    Yhtj() {
        let route = [
            <Route exact path="/yhtj" component={YhtjAction} />,
        ]
        return route
    }
    Wxtj() {
        let route = [
            <Route exact path="/wxtj" component={WxtjAction} />,
        ]
        return route
    }
    Jcxs() {
        let route = [
            <Route exact path="/jcxs" component={JcxsAction} />,
        ]
        return route
    }
    Jcxstj() {
        let route = [
            <Route exact path="/jcxstj" component={JcxstjAction} />,
        ]
        return route
    }
    Lrfx() {
        let route = [
            <Route exact path="/lrfx" component={LrfxAction} />,
        ]
        return route
    }
    Cbfx() {
        let route = [
            <Route exact path="/cbfx" component={CbfxAction} />,
        ]
        return route
    }
    Stxstj() {
        let route = [
            <Route exact path="/stxstj" component={StxstjAction} />,
        ]
        return route
    }
    Wjstj() {
        let route = [
            <Route exact path="/wjstj" component={WjstjAction} />,
        ]
        return route
    }
    Sztj() {
        let route = [
            <Route exact path="/yetj" component={YetjAction} />,
            <Route exact path="/fpjs" component={FpjsAction} />,
        ]
        return route
    }
    render() {
        return (
            <ConfigProvider locale={zhCN} input={{ autoComplete: false }}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/login" component={LoginAction} />
                        <HomeAction>
                            <Switch>
                                {this.Shouye()}
                                {/* 生产记录25 */}
                                {store.getState().data.info.qx25 ? (store.getState().data.info.qx25 != -1 ? this.Xmjh() : null) : null}
                                {/* 生产记录1 */}
                                {store.getState().data.info.qx1 ? (store.getState().data.info.qx1 != -1 ? this.Mrtjjl() : null) : null}
                                {/* 外租统计2 */}
                                {store.getState().data.info.qx2 ? (store.getState().data.info.qx2 != -1 ? this.Wdbmx() : null) : null}
                                {/* 加油统计3 */}
                                {store.getState().data.info.qx3 ? (store.getState().data.info.qx3 != -1 ? this.Jymx() : null) : null}
                                {/* 物资收发4 */}
                                {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 != -1 ? this.Jckgl() : null) : null}
                                {/* 泵管收发5 */}
                                {store.getState().data.info.qx5 ? (store.getState().data.info.qx5 != -1 ? this.Jckgl2() : null) : null}
                                {/* 车辆维修6 */}
                                {store.getState().data.info.qx6 ? (store.getState().data.info.qx6 != -1 ? this.Clwxmx() : null) : null}
                                {/* 维修费统计7 */}
                                {/* {store.getState().data.info.qx7?(store.getState().data.info.qx7!=-1?this.Wxftj():null):null} */}
                                {/* 商砼销售8 */}
                                {store.getState().data.info.qx8 ? (store.getState().data.info.qx8 != -1 ? this.Dhntmx() : null) : null}
                                {/* 应收应付9 */}
                                {store.getState().data.info.qx9 ? (store.getState().data.info.qx9 != -1 ? this.Yszkmx() : null) : null}
                                {/* 应收应付10 */}
                                {/* {store.getState().data.info.qx10?(store.getState().data.info.qx10!=-1?this.Yfzkmx():null):null} */}
                                {/* 财务收支11 */}
                                {store.getState().data.info.qx11 ? (store.getState().data.info.qx11 != -1 ? this.Szqk() : null) : null}
                                {/* 员工考勤12 */}
                                {store.getState().data.info.qx12 ? (store.getState().data.info.qx12 != -1 ? this.Kq() : null) : null}
                                {/* 工资计算13 */}
                                {store.getState().data.info.qx13 ? (store.getState().data.info.qx13 != -1 ? this.Gzjs() : null) : null}
                                {/* 结算信息14 */}
                                {store.getState().data.info.qx14 ? (store.getState().data.info.qx14 != -1 ? this.Jsmx() : null) : null}
                                {/* 合同信息15 */}
                                {store.getState().data.info.qx15 ? (store.getState().data.info.qx15 != -1 ? this.Htxx() : null) : null}
                                {/* 商务成本26 */}
                                {store.getState().data.info.qx26 ? (store.getState().data.info.qx26 != -1 ? this.Swcb() : null) : null}
                                {/* 员工信息表16 */}
                                {store.getState().data.info.qx16 ? (store.getState().data.info.qx16 != -1 ? this.Ygxx() : null) : null}
                                {/* 车辆信息表17 */}
                                {store.getState().data.info.qx17 ? (store.getState().data.info.qx17 != -1 ? this.Clxx() : null) : null}
                                {/* 信息表18 */}
                                {store.getState().data.info.qx18 ? (store.getState().data.info.qx18 != -1 ? this.Xinxibiao() : null) : null}
                                {/* 客户信息表18 */}
                                {/* {store.getState().data.info.qx18?(store.getState().data.info.qx18!=-1?this.Khxx():null):null} */}
                                {/* 工程信息表19 */}
                                {/* {store.getState().data.info.qx19?(store.getState().data.info.qx19!=-1?this.Gcxx():null):null} */}
                                {/* 系统账号20 */}
                                {store.getState().data.info.qx20 ? (store.getState().data.info.qx20 != -1 ? this.User() : null) : null}
                                {/* 总方量21 */}
                                {/* {store.getState().data.info.qx21?(store.getState().data.info.qx21!=-1?this.Zfl():null):null} */}
                                {/* 生产统计27 */}
                                {store.getState().data.info.qx27 ? (store.getState().data.info.qx27 != -1 ? this.Scjhfx() : null) : null}
                                {/* 油耗分析28 */}
                                {store.getState().data.info.qx28 ? (store.getState().data.info.qx28 != -1 ? this.Yhtj() : null) : null}
                                {/* 维修统计29 */}
                                {store.getState().data.info.qx29 ? (store.getState().data.info.qx29 != -1 ? this.Wxtj() : null) : null}
                                {/* 建材销售30 */}
                                {store.getState().data.info.qx30 ? (store.getState().data.info.qx30 != -1 ? this.Jcxs() : null) : null}
                                {/* 销售统计31 */}
                                {store.getState().data.info.qx31 ? (store.getState().data.info.qx31 != -1 ? this.Jcxstj() : null) : null}
                                {/* 成本分析32 */}
                                {store.getState().data.info.qx32 ? (store.getState().data.info.qx32 != -1 ? this.Cbfx() : null) : null}
                                {/* 利润分析33 */}
                                {store.getState().data.info.qx33 ? (store.getState().data.info.qx33 != -1 ? this.Lrfx() : null) : null}
                                {/* 商砼销售统计34 */}
                                {store.getState().data.info.qx34 ? (store.getState().data.info.qx34 != -1 ? this.Stxstj() : null) : null}
                                {/* 未结算统计35 */}
                                {store.getState().data.info.qx35 ? (store.getState().data.info.qx35 != -1 ? this.Wjstj() : null) : null}
                                {/* 业务成本合计22 */}
                                {/* {store.getState().data.info.qx22?(store.getState().data.info.qx22!=-1?this.Ywcbhj():null):null} */}
                                {/* 期间费用23 */}
                                {/* {store.getState().data.info.qx23?(store.getState().data.info.qx23!=-1?this.Qjfy():null):null} */}
                                {/* 利润统计24 */}
                                {/* {store.getState().data.info.qx24?(store.getState().data.info.qx24!=-1?this.Lrtj():null):null} */}
                                {store.getState().data.info.qx36 ? (store.getState().data.info.qx36 != -1 ? this.Sztj() : null) : null}
                                <Route path="*" component={Error} />
                            </Switch>
                        </HomeAction>
                    </Switch>
                </HashRouter>
            </ConfigProvider>
        );
    }
}

export default App;
