import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
import History from './history.js';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/xmjh', '生产计划')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight: yheight
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        fetch('Xmjh/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    projectid_react: data.projectid_react ? data.projectid_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    wdbdw_react: data.wdbdw_react ? data.wdbdw_react : [],
                    sgdwid_react: data.sgdwid_react ? data.sgdwid_react : [],
                    hntjbzid_react: data.hntjbzid_react ? data.hntjbzid_react : [],
                    month_react: data.month_react ? data.month_react : [],
                    xclx_react: data.xclx_react ? data.xclx_react : [],
                })
            })
    }
    delSel() {
        let that = this
        fetch('Xmjh/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Xmjh/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    delSel2(id, e) {
        let that = this
        fetch('/Xmjh/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '生产日期',
                dataIndex: 'scrq_str',
                key: 'scrq_str',
                width: 120,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划名称',
                dataIndex: 'jhmc',
                key: 'jhmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '客户名称',
                dataIndex: 'khmc',
                key: 'khmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '生产时间',
                dataIndex: 'scsj',
                key: 'scsj',
                width: 80,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '计划方量',
                dataIndex: 'jhfl',
                key: 'jhfl',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '项目名称',
                dataIndex: 'xmmc',
                key: 'xmmc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '自有设备',
                dataIndex: 'zysb_arr',
                key: 'zysb_arr',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租设备',
                dataIndex: 'wzsb_arr',
                key: 'wzsb_arr',
                ellipsis: {
                    showTitle: false,
                },
                width: 130,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机',
                dataIndex: 'siji_arr',
                key: 'siji_arr',
                ellipsis: {
                    showTitle: false,
                },
                width: 130,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '施工单位',
                dataIndex: 'sgdw_str',
                key: 'sgdw_str',
                ellipsis: {
                    showTitle: false,
                },
                width: 130,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '混凝土搅拌站',
                dataIndex: 'hntjbz_str',
                key: 'hntjbz_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)} />
                                <History id={record.id} />
                                {store.getState().data.info.qx25 ? (store.getState().data.info.qx25 == 1 ?
                                    <>
                                        {record.nodel == 1 ?
                                            null :
                                            <Popconfirm
                                                title="确定删除?"
                                                onConfirm={this.delSel2.bind(this, record.id)}
                                                okText="确定"
                                                cancelText="取消"
                                            >
                                                <a href="#" className="hongse">删除</a>
                                            </Popconfirm>
                                        }
                                    </>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align="top" >
                        <Space wrap style={{ width: 'calc(100% - 100px)' }}>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <Input placeholder="计划名称" value={this.state.newcondition.jhmc ? this.state.newcondition.jhmc : null} onChange={this.onChange.bind(this, 'jhmc')} />
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'scrq')} value={[(this.state.newcondition.scrq1 ? moment(this.state.newcondition.scrq1) : null), (this.state.newcondition.scrq2 ? moment(this.state.newcondition.scrq2) : null)]} placeholder={['生产日期', '生产日期']} />
                            <Select placeholder="项目名称" value={this.state.newcondition.projectid ? this.state.newcondition.projectid : null} onChange={this.onSelChange.bind(this, 'projectid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.projectid_react)}
                            </Select>
                            {/* <Select placeholder="车辆编号" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 150 }}  allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select> */}
                            <Select placeholder="施工单位" value={this.state.newcondition.sgdwid ? this.state.newcondition.sgdwid : null} onChange={this.onSelChange.bind(this, 'sgdwid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sgdwid_react)}
                            </Select>
                            <Select placeholder="混凝土搅拌站" value={this.state.newcondition.hntjbzid ? this.state.newcondition.hntjbzid : null} onChange={this.onSelChange.bind(this, 'hntjbzid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.hntjbzid_react)}
                            </Select>
                            <Select placeholder="自有设备" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Select placeholder="外租设备" value={this.state.newcondition.wdbdw ? this.state.newcondition.wdbdw : null} onChange={this.onSelChange.bind(this, 'wdbdw')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.wdbdw_react)}
                            </Select>
                            <Select placeholder="行车路线" value={this.state.newcondition.xclx ? this.state.newcondition.xclx : null} onChange={this.onSelChange.bind(this, 'xclx')} style={{ width: 150 }} allowClear showSearch optionFilterProp="children">
                                {this.arrsel(this.state.xclx_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx25 ? (store.getState().data.info.qx25 == 1 ?
                            <div style={{ maxWidth: 100 }}>
                                <Space wrap gutter={[6, 6]} style={{ justifyContent: 'flex-end' }}>
                                    <Add refresh={this.getdata.bind(this)} />
                                    <Button type="primary" onClick={this.download.bind(this)} loading={this.state.downloading} className='blue'>导出</Button>
                                </Space>
                            </div>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{ y: this.state.tableHeight ? this.state.tableHeight : null }}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totaljhfl = 0;
                        pageData.forEach(({ jhfl }) => {
                            totaljhfl += Number(jhfl ? jhfl : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totaljhfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
