import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker } from 'antd';
import store from "../store/store.js";
import { FormOutlined,InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
import Jytj from './jytj';
const { Option } = Select;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                { id: 1, name: '1月' },
                { id: 2, name: '2月' },
                { id: 3, name: '3月' },
                { id: 4, name: '4月' },
                { id: 5, name: '5月' },
                { id: 6, name: '6月' },
                { id: 7, name: '7月' },
                { id: 8, name: '8月' },
                { id: 9, name: '9月' },
                { id: 10, name: '10月' },
                { id: 11, name: '11月' },
                { id: 12, name: '12月' },
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/jymx'+(this.props.match.params.iswz ? '/' + this.props.match.params.iswz : ''), '加油统计')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize',this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 240 - selheight
        that.setState({
            tableHeight:yheight
        })
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} >{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.iswz = that.props.match.params.iswz ? that.props.match.params.iswz : 0
        fetch('/Jymx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    sj_react: data.sj_react ? data.sj_react : [],
                    sblx_react: data.sblx_react ? data.sblx_react : [],
                    zffs_react: data.zffs_react ? data.zffs_react : [],
                    jydd_react: data.jydd_react ? data.jydd_react : [],
                })
            })
    }
    delSel() {
        let that = this
        fetch('/Jymx/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    delSel2(id, e) {
        let that = this
        fetch('/Jymx/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Jymx/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, { [name + '1']: dateStrings[0], [name + '2']: dateStrings[1] })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        <Space><span>{row}</span>{record.warning==1?<InfoCircleOutlined className="hongse" />:null}</Space>
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '类型',
                dataIndex: 'sblx',
                key: 'sblx',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '司机',
                dataIndex: 'sj_str',
                key: 'sj_str',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi_str',
                key: 'riqi_str',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '时间',
                dataIndex: 'shijian_str',
                key: 'shijian_str',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '送油升数',
                dataIndex: 'syss',
                key: 'syss',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油升数',
                dataIndex: 'jyss',
                key: 'jyss',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '单价',
                dataIndex: 'dj',
                key: 'dj',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '金额',
                dataIndex: 'je',
                key: 'je',
                ellipsis: {
                    showTitle: false,
                },
                width:100,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加油地点',
                dataIndex: 'jydd_str',
                key: 'jydd_str',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '支付方式',
                dataIndex: 'zffs_str',
                key: 'zffs_str',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },

            {
                title: '经办人',
                dataIndex: 'jbr_str',
                key: 'jbr_str',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '审核人',
                dataIndex: 'shr_str',
                key: 'shr_str',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '创建日期',
                dataIndex: 'cjrq_str',
                key: 'cjrq_str',
                ellipsis: {
                    showTitle: false,
                },
                width:120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)} />
                                {store.getState().data.info.qx3 ? (store.getState().data.info.qx3 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : 0} onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'riqi')} value={[(this.state.newcondition.riqi1 ? moment(this.state.newcondition.riqi1) : null), (this.state.newcondition.riqi2 ? moment(this.state.newcondition.riqi2) : null)]} placeholder={['日期', '日期']} />
                            <Select placeholder="车牌" value={this.state.newcondition.chid ? this.state.newcondition.chid : null} onChange={this.onSelChange.bind(this, 'chid')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Select placeholder="类型" value={this.state.newcondition.sblx ? this.state.newcondition.sblx : null} onChange={this.onSelChange.bind(this, 'sblx')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sblx_react)}
                            </Select>
                            <Select placeholder="支付方式" value={this.state.newcondition.zffs ? this.state.newcondition.zffs : null} onChange={this.onSelChange.bind(this, 'zffs')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.zffs_react)}
                            </Select>
                            <Select placeholder="司机" value={this.state.newcondition.sj ? this.state.newcondition.sj : null} onChange={this.onSelChange.bind(this, 'sj')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.sj_react)}
                            </Select>
                            <Select placeholder="加油地点" value={this.state.newcondition.jydd ? this.state.newcondition.jydd : null} onChange={this.onSelChange.bind(this, 'jydd')} allowClear style={{ width: 150 }} showSearch optionFilterProp="children">
                                {this.arrsel(this.state.jydd_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                            <Jytj condition={this.state.oldcondition} />
                        </Space>
                        {store.getState().data.info.qx3 ? (store.getState().data.info.qx3 == 1 ?
                            <Space wrap>
                                <Add refresh={this.getdata.bind(this)} />
                                <Button type="primary" className='blue' onClick={this.download.bind(this)} >导出</Button>
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{ x: 2200,y:this.state.tableHeight?this.state.tableHeight:null }}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalsyss = 0;
                        let totaljyss = 0;
                        // let totaldj = 0;
                        let totalje = 0;
                        pageData.forEach(({ syss,jyss,dj,je}) => {
                            totalsyss += Number(syss ? syss : 0);
                            totaljyss += Number(jyss ? jyss : 0);
                            // totaldj += Number(dj ? dj : 0);
                            totalje += Number(je ? je : 0);
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totalsyss.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{totaljyss.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>{totalje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div>
            </>
        )
    }
}
